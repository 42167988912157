import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import siteJSON from "../constants/sites.json"
import { Container, PostText } from "../utils/common-styles"
import styled from "styled-components"
import colors from "../utils/colors"

const PostTitle = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.DARK_BLUE};
`




export default function techpage({ data }) {
  const { title} = data.contentfulTechStack
  return (
  <Layout>
    <SEO title={title} />
    <Container>
      <h1>Här är de topp 15 bästa platserna att hitta en {title}</h1>
     
      {Object.entries(siteJSON).map(([key, val], index) => (
          <React.Fragment key={key}>
            <PostTitle>{`${index + 1}. ${key}`}</PostTitle>
            <PostText>
              {val.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </PostText>
          </React.Fragment>
        ))}
          </Container>
  </Layout>
  )
}



export const pageQuery = graphql`
  query TechPageBySlug($slug: String!) {
    contentfulTechStack(slug: {eq: $slug}) {
      slug
      title
      logo {
        title
        fluid(maxWidth: 960) {
          src
        }
      }
    }
  }
`
